<template>
  <section>
    <!-- Header -->
    <Header @show-menu="toggleMenuProfile" v-if="!checkWebview()"/>

    <!-- content -->
    <router-view @openSearch="toggleModalSearch" :key="$route.path"/>

    <!-- Footer -->
    <Footer v-if="!checkWebview()"/>
    <!-- Menu footer on SP -->
    <ListMenu class="hidden-pc"
              v-if="!checkWebview()"
              @toggleSearch="toggleModalSearch"/>

    <!-- modal search -->
    <ModalSearch @closeModal="toggleModalSearch"
                 v-if="flagModalSearch"/>

    <!-- menu left in profile -->
    <div :class="{'show' : flagMenuProfile}"
         class="wrap-menu-profile">
      <x-icon @click="toggleMenuProfile" class="close-menu"/>
      <MenuLeft @close-menu="toggleMenuProfile" />
    </div>
  </section>
</template>

<script>
import MenuLeft from '@/components/profile/MenuLeft'
import ListMenu from '@/components/ListMenu'
import ModalSearch from '@/components/ModalSearch'
import Footer from '@/layouts/Footer'

import Header from '@/layouts/Header'
import { XIcon } from 'vue-feather-icons'
import { mapState } from 'vuex'
import store from '@/store'
import Common from '@/mixins/common.mixin'
import { HEADER_TITLE } from '@/enum/title-header'

export default {
  name: 'Resource',

  components: {
    MenuLeft,
    ListMenu,
    ModalSearch,
    Footer,
    Header,
    XIcon
  },

  mixins: [Common],

  computed: {
    ...mapState('auth', ['apiErr', 'token']),
    ...mapState('cart', ['flagModal']),
    ...mapState('user', ['flagModalSearch'])
  },

  created () {
    store.dispatch('category/getListTitle', { with: 'children.children' })
    window.addEventListener('scroll', this.handleScroll)
  },

  watch: {
    '$route.name': {
      handler () {
        // CHECK LOGIN IN WEBVIEW
        if (this.checkWebview()) {
          // check api error to open page native
          if (this.apiErr) {
            this.$store.commit('auth/SET_API_ERROR', false)
            this.openPageNative('PageNotFound')
          }
        }
      },
      immediate: true
    },

    apiErr: {
      handler () {
        if (this.checkWebview()) {
          if (this.apiErr) {
            this.$store.commit('auth/SET_API_ERROR', false)
            this.openPageNative('PageError')
          }
        }
      },
      immediate: true
    }
  },

  data () {
    return {
      flagMenuProfile: false,
      HEADER_TITLE
    }
  },

  methods: {
    toggleModalSearch () {
      this.$store.commit('user/DISPLAY_MODAL', !this.flagModalSearch)
    },

    handleScroll () {
      const sidebar = document.getElementById('side-bar')
      const mainContent = document.querySelector('.home-content')

      // handle scroll top sidebar left when scroll top header
      if (sidebar && mainContent) {
        const scrollHeight = window.pageYOffset
        const topMainContent = mainContent.offsetTop

        if (scrollHeight < topMainContent) {
          sidebar.scrollTop = 0
        }
      }
    },

    toggleMenuProfile () {
      this.flagMenuProfile = !this.flagMenuProfile
    }
  }
}
</script>

<style scoped>

</style>
