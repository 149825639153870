<template>
  <header :class="{ 'header-profile': defineProfile($route.name) }">
    <template>
      <div class="alert alert-warning" v-if="token && profile.skip_regis_info">
        <router-link class="profile-link" to="/change-profile">
          会員情報に未入力の項目があります
        </router-link>

      </div>
      <div class="container" v-else-if="checkMobile()">
        <div class="open-app-container" >
          <p class="content-download">アプリでポイ活をもっと快適に！</p>
          <p class="btn-open-app" @click="openApp">アプリを開く</p>
        </div>
      </div>
    </template>
    <div class="header-top container">
      <router-link class="wrap-logo" to="/">
        <img src="../assets/images/logo-point-site.png" class="logo pc" alt="logo">
        <img src="../assets/images/logo-point-site.svg" class="logo sp" alt="">
      </router-link>

      <div class="content-right">
        <!-- btn search -->
        <form class="btn-search">
          <img src="../assets/images/icon/icon-search.png" alt="icon-search">
          <input v-model="searchValue" type="text" placeholder="キーワードを入力">
          <button @click.prevent="handleSearch"
                  type="submit"
                  :class="{'bg-black': (token && profile)}">検索</button>
        </form>

        <!-- block right -->
        <div class="block-btn-right">
          <!-- user is logged in -->
          <div class="account-user" v-if="(token && profile)">
            <p>{{profile.nickname}}</p>
            <span v-if="user_point.total_point_amount > 999999">999,999+P</span>
            <span v-else>{{ user_point.total_point_amount | numberShort(',', 'P') }}</span>
          </div>

          <!-- notify for user is login in -->
          <div class="control-user" v-if="(token && profile)">

            <!-- button notify -->
            <router-link :to="{name: 'Profile.News.List'}" class="notify btn-has-icon">
              <!-- notify new -->
              <span v-if="user_point.is_news"></span>
              <img :src="defineProfile($route.name) ? require('../assets/images/icon/ios-notifications.svg')
                        : require('../assets/images/icon/icon-notification-green.svg')" alt="icon bell">
            </router-link>

            <!-- button heart -->
            <router-link :to="{name: 'Profile.FavoriteAds'}" class="like btn-has-icon">
              <img :src="defineProfile($route.name) ? require('../assets/images/icon/icon-heart-orange.svg')
                        : require('../assets/images/icon/icon-heart-green.svg')" alt="icon-heart">
            </router-link>

            <!-- button profile -->
            <div class="btn-group">
              <!-- btn link to profile from other page -->
              <router-link to="/profile/mypage"
                            class="btn-profile pc">
                マイページ
              </router-link>

              <!-- btn dropdown menu in page profile -->
              <button @click="$emit('show-menu', $event)"
                      class="btn-profile in-profile">マイページ</button>

            </div>

            <!-- logout icon -->
            <log-out-icon v-if="(token && profile)" size="1.5x"
                          class="icon-logout cursor-pointer pc"
                          @click="handleLogout" />
          </div>

          <div class="btn-not-login" v-else>
            <a @click="redirectRegister" class="btn-header btn-join-free">無料会員登録
            </a>
            <router-link :to="{name: 'Login'}"
                         class="btn-header btn-login">
              <img src="../assets/images/icon/icon-login.png" alt="icon-login">ログイン
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- menu header -->
    <ListMenu class="hidden-sp"/>
  </header>
</template>

<script>
import ListMenu from '@/components/ListMenu'
import { mapActions, mapState } from 'vuex'
import { LogOutIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import Cookie from 'js-cookie'
import { giftCodeStorageKey } from '@/enum/gift-code'

export default {
  name: 'Header',

  components: {
    ListMenu,
    LogOutIcon
  },

  mixins: [Common],

  data () {
    return {
      searchValue: ''
    }
  },

  computed: {
    ...mapState('auth', ['token', 'profile', 'user_point'])
  },

  methods: {
    ...mapActions('auth', ['userLogout']),

    openSearch () {
      this.$emit('openSearch')
    },

    async handleLogout () {
      await this.userLogout()

      Cookie.remove(giftCodeStorageKey)

      if (this.$route.name !== 'Index') {
        await this.$router.push({ name: 'Index' })
      }
    },

    handleSearch () {
      this.$router.replace({
        name: 'CategoryList.Search',
        query: { keyword: this.searchValue }
      }).catch(() => {})
    },

    redirectRegister () {
      // click reload page when send mail but not over yet step 2
      if (this.$route.name === 'Register') {
        window.location.href = window.location.origin + '/registration'
      } else this.$router.push({ name: 'Register' })
    },
    openApp () {
      window.open(process.env.VUE_APP_UNIVERSAL_LINK)
    }
  }
}
</script>

<style lang="scss" scoped>

header {
  background: #ffffff;
  border-bottom: 1px solid #615954;
  overflow-x: hidden;
  position: relative;
  z-index: 20;
  @media #{$info-phone} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .alert {
    width: 100%;
    padding: 16px 20px;
    text-align: center;
    @media #{$info-phone} {
      padding: 10px 20px;
    }
    .profile-link {
      color: #fff;
      @media #{$info-phone} {
      font-size: 16px;
      }
    }
    &-warning {
      background: #c6aa44;
    }
  }
}
.wrap-logo {
  flex-shrink: 0;
  @media #{$info-tablet-horizontal} {
    align-self: center;
  }
}
.logo {
  max-width: 321px;
  padding: 33px 40px 7px 0;
  object-fit: contain;
  @media #{$info-tablet-horizontal} {
    padding: 15px;
  }
  @media #{$info-phone} {
    width: 100px;
    padding: 0;
  }
}
.header-top {
  display: flex;
  align-items: flex-start;
  @media #{$info-phone} {
    align-items: center;
  }
  .content-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 12px 12px;
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 10px;
    &:after {
      content: '';
      position: absolute;
      width: -100%;
      height: 100%;
      left: 0;
      right: -100%;
      background: #F5F5F5;
      z-index: -1;
      border-bottom-left-radius: 10px;
      @media #{$info-phone} {
        content: unset;
      }
      .header-profile & {
        background: #F5F0ED;
      }
    }
    @media #{$info-tablet-horizontal} {
      justify-content: flex-end;
      padding-right: 15px;
      flex-wrap: wrap;
    }
    @media #{$info-phone} {
      padding: 5px 0 7px;
    }
    .btn-search {
      position: relative;
      display: flex;
      align-items: center;
      height: 36px;
      width: 264px;
      background: #ffffff;
      border-radius: 3px;
      padding: 7px 64px 7px 36px;
      margin-right: 24px;
      @media #{$info-tablet-horizontal} {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
      }
      @media #{$info-phone} {
        display: none;
      }
      img {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
      }
      input {
        width: 100%;
      }
      button {
        position: absolute;
        right: 3px;
        top: 3px;
        line-height: 1;
        padding: 7px 11px;
        background: #73483E;
        border-radius: 3px;
        color: #ffffff;
        cursor: pointer;
        // bg button when logged
        &.bg-black {
          background: #444444;
        }
      }
    }

    .account-user {
      display: flex;
      align-items: center;
      @media #{$info-phone} {
        display: none;
      }
      p {
        white-space: nowrap;
        font-family: $font-family-title-B;
      }
      span {
        font-family: $font-label;
        font-weight: bold;
        font-style: italic;
        color: #7C934C;
        padding-left: 12px;
        border-left: 1px solid #7C934C;
        margin: 0 auto 0 5px;
        @include font-size(20px);
        @media #{$info-laptop-normal} {
          margin: 0 15px;
        }
        .header-profile & {
          color: #D98F6D;
          border-left: 1px solid #C66C44;
        }
      }
    }

    .control-user {
      display: flex;
      align-items: center;
      .btn-has-icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #ffffff;
        margin: 0 4px;
        cursor: pointer;
        @media #{$info-phone} {
          background: #F5F5F5;
        }
        &.notify {
          position: relative;
          span {
            position: absolute;
            width: 8px;
            height: 8px;
            background: #E50023;
            border-radius: 50%;
            right: 0;
            top: 0;
          }
        }
      }
      .btn-group {
        position: relative;
        display: inline-block;
        margin-left: 8px;

        .btn-profile {
          display: flex;
          align-items: center;
          padding: 5px 8px;
          font-size: 14px;
          font-family: $font-family-title;
          border-radius: 13px;
          cursor: pointer;
          border: 1px solid #7C934C;
          background: transparent;
          color: #7C934C;
          line-height: 1;
          white-space: nowrap;
          @media #{$info-phone} {
            padding: 8px 16px;
            border-radius: 16px;
            background: #7C934C;
            color: #FFFFFF;
          }
          &.in-profile {
           display: none;
            @media #{$info-phone} {
              display: flex;
            }
          }
          .header-profile & {
            border-color: transparent;
            background: #C66C44;
            color: #ffffff;
          }
        }
      }

      .icon-logout {
        margin-left: 11px;
      }

      @media #{$info-phone} {
        .logout-sp {
          display: none;
        }
      }
    }

    .block-btn-right {
      display: flex;
      justify-content: space-between;
      flex: 1;
      @media #{$info-phone} {
       justify-content: flex-end;
      }
      .btn-not-login {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
      .btn-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 144px;
        height: 44px;
        border-radius: 3px;
        @media #{$info-phone} {
          font-size: 12px;
          width: 94px;
          height: 28px;
          line-height: 1;
        }
      }
      .btn-join-free {
        color: #ffffff;
        background: #7C934C;
        @media #{$info-phone} {
          padding: 4px 8px;
        }
      }
      .btn-login {
        margin-left: 8px;
        display: flex;
        align-items: center;
        border: 1px solid #7C934C;
        background: #ffffff;
        color: #7C934C;
        @media #{$info-phone} {
          width: 89px;
        }
        img {
          width: 22px;
          margin-right: 12px;
          @media #{$info-phone} {
            width: 17px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.list-menu {
  font-family: $font-family-title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 15px 11px;

  @media #{$info-tablet-horizontal} {
    padding: 23px 15px 11px;
  }
  @media #{$info-phone} {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    padding: 7px 20px 3px;
    background: #ffffff;
    border-top: 1px solid #444444;
  }
  li {
    @media #{$info-tablet-horizontal} {
      margin-left: 10px;
    }
    @media #{$info-phone} {
      margin-left: 0;
    }
    .item {
      display: flex;
      align-items: center;
      color: $default-color;
      padding: 5px 10px 5px 0;
      border-bottom: 4px solid transparent;
      transition: .5s all;
      @media #{$info-phone} {
        flex-direction: column;
        font-size: 12px;
        border-bottom: 0;
        align-items: center;
      }
      &:last-child {
        padding-right: 0;
      }
      &.active,
      &:hover {
        color: $default-green;
        border-bottom: 4px solid $default-green;
        transition: .5s all;
        @media #{$info-phone} {
          border-bottom: 0;
          color: $default-green;
        }
      }
      img {
        margin-right: 8px;
        @media #{$info-phone} {
          margin: 0 0 3px;
        }
      }
    }
  }
}
.hidden-sp {
  @media #{$info-phone} {
    display: none;
  }
}

.container:has(.open-app-container) {
  background-color: #f8b334;
  max-width: 100%;
  .open-app-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: 10px 0;
    .btn-open-app {
      padding: 4px 30px;
      border-radius: 3px;
      background-color: #fff;
      color: #282828;
      @media #{$info-phone} {
        padding: 5px 10px;
      }

    }
    @media #{$info-tablet-horizontal} {
      padding: 9px 0;
    }
    .content-download {
      @media #{$info-tablet-horizontal} {
        padding-left: 6px;
        font-size: 18px;
      }
      @media #{$info-phone} {
        padding: 0;
        font-size: 14px;
      }
    }
  }
}
</style>
