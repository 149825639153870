import {
  getListAdvertises,
  getListRecommended,
  show,
  getListRecentSeen,
  getListAdvertisesNew,
  getListRecommendModal
} from '@/services/advertises.service'

const initialState = {
  advertiseList: [],
  recommendedList: [],
  recentSeenList: [],
  newList: [],
  listRecommendModal: [],
  pagination: [],
  pageActive: 1,
  detail: null,
  isKeepModal: false,
  isShare: false,
  advIdOpenShared: null
}

export const state = { ...initialState }

const getters = {}

const mutations = {
  SET_LIST (state, payload) {
    state.advertiseList = payload
  },
  SET_DETAIL (state, payload) {
    state.detail = payload
  },
  SET_LIST_RECOMMENDED (state, payload) {
    state.recommendedList = payload
  },
  SET_LIST_RECENT_SEEN (state, payload) {
    state.recentSeenList = payload
  },
  SET_LIST_NEW (state, payload) {
    state.newList = payload
  },
  SET_LIST_RECOMMENDED_MODAL (state, payload) {
    state.listRecommendModal = payload
  },
  SET_PAGINATION (state, payload) {
    state.pagination = payload
  },
  SET_PAGE_ACTIVE (state, page) {
    state.pageActive = page
  },
  SET_KEEP_MODAL (state, payload) {
    state.isKeepModal = payload
  },
  SET_HANDLE_SHARE (state, payload) {
    state.isShare = payload
  },
  RESET_MODAL (state) {
    state.isShare = false
    state.isKeepModal = false
    state.advIdOpenShared = null
  },
  SET_ADV_OPEN_MODAL (state, payload) {
    state.advIdOpenShared = payload
  }
}

const actions = {
  getListAdvertises ({ commit, dispatch }, params = {}) {
    return getListAdvertises(params).then((res) => {
      commit('SET_LIST', res.data.data)
      if (res.data.pagination) {
        commit('SET_PAGINATION', res.data.pagination)
      }
      return res
    }).catch(err => {
      return err.response.data
    })
  },

  getListRecommended ({ commit }, [id, params = {}]) {
    return getListRecommended(id, params).then(res => {
      commit('SET_LIST_RECOMMENDED', res.data.data)

      return res
    }).catch(err => {
      return err.response.data
    })
  },

  getAdvByID ({ commit }, [id, params = {}]) {
    return show(id, params).then(res => {
      commit('SET_DETAIL', res.data.data)

      return true
    }).catch(_ => {
      return false
    })
  },

  getListRecentSeen ({ commit }, params = {}) {
    return getListRecentSeen(params).then((res) => {
      commit('SET_LIST_RECENT_SEEN', res.data.data)
      commit('SET_PAGINATION', res.data.pagination)
      return res
    }).catch(err => {
      return err.response.data
    })
  },
  getListNew ({ commit }, params = {}) {
    return getListAdvertisesNew(params).then((res) => {
      commit('SET_LIST_NEW', res.data.data)
      commit('SET_PAGINATION', res.data.pagination)
      return res
    }).catch(err => {
      return err.response.data
    })
  },
  getListRecommendModal ({ commit }, params = {}) {
    return getListRecommendModal(params).then((res) => {
      commit('SET_LIST_RECOMMENDED_MODAL', res.data.data)
      return res
    }).catch(err => {
      return err.response.data
    })
  },
  setKeepModal ({ commit }, params) {
    commit('SET_KEEP_MODAL', params)
  },
  setHandleShare ({ commit }, params) {
    commit('SET_HANDLE_SHARE', params)
  },
  resetModal ({ commit }) {
    commit('RESET_MODAL')
  },
  setAdvOpenModal ({ commit }, params) {
    commit('SET_ADV_OPEN_MODAL', params)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
