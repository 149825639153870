<template>
  <ul class="container menu-header-common">
    <li>
      <router-link to="/"
                   :class="{'active': $route.name === 'Index'}"
                   class="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="18.17" height="20" viewBox="0 0 18.17 20">
          <path id="home" d="M17.809,6.553,9.638.213A.944.944,0,0,0,9.053,0a.709.709,0,0,0-.521.213L.362,6.553a.892.892,0,0,0-.277.34A.936.936,0,0,0,0,7.277v10a2.632,2.632,0,0,0,.787,1.936A2.632,2.632,0,0,0,2.723,20H15.447a2.689,2.689,0,0,0,2.723-2.723v-10a.936.936,0,0,0-.085-.383A.892.892,0,0,0,17.809,6.553ZM10.894,18.17H7.277V10.915h3.617Zm5.447-.894a.841.841,0,0,1-.894.894H12.723V10a.857.857,0,0,0-.255-.649.914.914,0,0,0-.66-.245H6.362a.914.914,0,0,0-.66.245A.857.857,0,0,0,5.447,10v8.17H2.723a.841.841,0,0,1-.894-.894V7.745L9.085,2.106,16.34,7.745Z" transform="translate(0 0)" fill="#282828"/>
        </svg>
        <span>ホーム</span>
      </router-link>
    </li>
    <li class="pc">
      <router-link :to="{ name: 'CategoryList.Category',
                          params: {id: 'all'},
                          query: { typeColor: 1 }}"
                   :class="{'active': $route.name === 'CategoryList.Category',
                            'colorPink': colorPink}"
                   class="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="17.259" height="18.153" viewBox="0 0 17.259 18.153">
          <path id="category" d="M0,10.44H7.287v7.287H0Zm10.27,6.52a3.945,3.945,0,0,1-1.193-2.9,4.074,4.074,0,0,1,4.091-4.091,4.074,4.074,0,0,1,4.091,4.091,4.074,4.074,0,0,1-4.091,4.091A3.945,3.945,0,0,1,10.27,16.96ZM8.182,0l4.986,8.182H3.2Z" fill="#282828"/>
        </svg>
        <span>カテゴリ</span>
      </router-link>
    </li>
    <li class="sp">
      <div class="item"
           :class="{ 'active' : flagModalSearch }"
           @click="toggleSearch">
        <svg id="icon--search" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <rect id="Rectangle_265" data-name="Rectangle 265" width="16" height="16" transform="translate(0 0)" fill="none"/>
          <path id="search" d="M15.756,14.614,12.8,11.672a7.677,7.677,0,0,0,1.152-2.061,6.763,6.763,0,0,0,.44-2.417,7.058,7.058,0,0,0-.562-2.81A7.157,7.157,0,0,0,10,.562,7.058,7.058,0,0,0,7.194,0a7.058,7.058,0,0,0-2.81.562A7.157,7.157,0,0,0,.562,4.384,7.058,7.058,0,0,0,0,7.194,7.058,7.058,0,0,0,.562,10a7.157,7.157,0,0,0,3.822,3.822,7.058,7.058,0,0,0,2.81.562,7.149,7.149,0,0,0,2.426-.412,6.314,6.314,0,0,0,2.052-1.2l2.96,2.96a.773.773,0,0,0,.29.187.685.685,0,0,0,.543,0,.784.784,0,0,0,.29-1.311ZM1.611,7.194A5.535,5.535,0,0,1,2.042,5a5.4,5.4,0,0,1,1.19-1.78A5.91,5.91,0,0,1,5,2.023a5.624,5.624,0,0,1,2.192-.431,5.535,5.535,0,0,1,2.192.431A5.607,5.607,0,0,1,12.365,5,5.535,5.535,0,0,1,12.8,7.194a5.428,5.428,0,0,1-.44,2.155A5.7,5.7,0,0,1,11.2,11.11h0A5.536,5.536,0,0,1,7.288,12.7a5.564,5.564,0,0,1-4.047-1.555,5.327,5.327,0,0,1-1.2-1.78A5.488,5.488,0,0,1,1.611,7.194Z" transform="translate(0 0)"/>
        </svg>
        <span>検索</span>
      </div>
    </li>
    <li>
      <router-link to="/feature-list"
                   :class="{'active': $route.name === 'FeatureList'}"
                   class="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.169" height="18.258" viewBox="0 0 19.169 18.258">
          <path id="star" d="M19.125,6.876a.683.683,0,0,0-.244-.387,1.385,1.385,0,0,0-.448-.224L12.88,5.493,10.357.468A.743.743,0,0,0,9.584,0a.743.743,0,0,0-.773.468L6.289,5.411.816,6.266a.643.643,0,0,0-.458.183,1.519,1.519,0,0,0-.315.427.869.869,0,0,0-.01.509.634.634,0,0,0,.254.366L4.3,11.657,3.339,17.21a.78.78,0,0,0,.041.5.876.876,0,0,0,.305.376,1.088,1.088,0,0,0,.437.163.885.885,0,0,0,.519-.081l4.943-2.6,4.943,2.6h.448a.707.707,0,0,0,.254-.051,1.3,1.3,0,0,0,.254-.132.751.751,0,0,0,.305-.356.78.78,0,0,0,.041-.5l-.956-5.554,4.008-3.906a.736.736,0,0,0,.254-.366A.616.616,0,0,0,19.125,6.876ZM13.307,10.7a1.869,1.869,0,0,0-.183.4,1.246,1.246,0,0,0-.061.376l.692,4.272L9.93,13.732a.77.77,0,0,0-.4-.122.67.67,0,0,0-.376.122L5.333,15.746l.773-4.272a1.246,1.246,0,0,0-.061-.376,1.869,1.869,0,0,0-.183-.4L2.729,7.751,6.98,7.14a.6.6,0,0,0,.387-.153q.183-.153.305-.275L9.584,2.807,11.5,6.632a.588.588,0,0,0,.244.305.789.789,0,0,0,.448.122l4.252.61Z" transform="translate(0)" fill="#282828"/>
        </svg>
        <span>特集</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'CategoryList.Receipt',
                          params: {id: 'all'},
                          query: { typeColor: 1 }}"
                   :class="{'active': $route.name === 'CategoryList.Receipt',
                             'colorPink': colorPink}"
                   class="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.364" height="17.813" viewBox="0 0 16.364 17.813">
          <path id="receipt" d="M16.364,0a.332.332,0,0,0-.3.128l-.724.724a.463.463,0,0,1-.639,0L13.977.128a.463.463,0,0,0-.639,0l-.724.724a.463.463,0,0,1-.639,0L11.25.128a.463.463,0,0,0-.639,0L9.886.852a.463.463,0,0,1-.639,0L8.523.128a.463.463,0,0,0-.639,0L7.159.852a.463.463,0,0,1-.639,0L5.753.128a.463.463,0,0,0-.639,0L4.389.852a.463.463,0,0,1-.639,0L3.026.128a.463.463,0,0,0-.639,0L1.662.852a.463.463,0,0,1-.639,0L.3.128A.332.332,0,0,0,0,0V17.813a.332.332,0,0,0,.3-.128l.724-.724a.463.463,0,0,1,.639,0l.724.724a.463.463,0,0,0,.639,0l.724-.724a.463.463,0,0,1,.639,0l.724.724a.463.463,0,0,0,.639,0l.724-.724a.463.463,0,0,1,.639,0l.724.724a.463.463,0,0,0,.639,0L9.2,16.96a.463.463,0,0,1,.639,0l.724.724a.463.463,0,0,0,.639,0l.724-.724a.463.463,0,0,1,.639,0l.724.724a.463.463,0,0,0,.639,0l.724-.724a.463.463,0,0,1,.639,0l.724.724a.484.484,0,0,0,.341.128ZM12.741,13.466H3.622a.916.916,0,0,1,0-1.832h9.119a.916.916,0,0,1,0,1.832Zm0-3.665H3.622a.895.895,0,1,1,0-1.79h9.119a.895.895,0,0,1,0,1.79Zm0-3.622H3.622a.916.916,0,0,1,0-1.832h9.119a.916.916,0,0,1,0,1.832Z" fill="#282828"/>
        </svg>
        <span>レシート</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'CategoryList.Survey',
                          params: {id: 'all'},
                          query: { typeColor: 1 }}"
                   :class="{'active': $route.name === 'CategoryList.Survey',
                            'colorPink': colorPink}"
                   class="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.358" height="20" viewBox="0 0 16.358 20">
          <path id="clipboard" d="M13.632,1.81h-.916A1.743,1.743,0,0,0,12.183.532,1.743,1.743,0,0,0,10.905,0H5.453A1.743,1.743,0,0,0,4.175.532,1.743,1.743,0,0,0,3.642,1.81H2.726A2.634,2.634,0,0,0,.788,2.6,2.634,2.634,0,0,0,0,4.537V17.274a2.634,2.634,0,0,0,.788,1.938A2.634,2.634,0,0,0,2.726,20H13.632a2.691,2.691,0,0,0,2.726-2.726V4.537A2.691,2.691,0,0,0,13.632,1.81Zm-8.179,0h5.453V3.642H5.453V2.726h0Zm9.095,15.463a.858.858,0,0,1-.256.65.915.915,0,0,1-.66.245H2.726a.915.915,0,0,1-.66-.245.858.858,0,0,1-.256-.65V4.537a.858.858,0,0,1,.256-.65.915.915,0,0,1,.66-.245h.916A1.743,1.743,0,0,0,4.175,4.92a1.743,1.743,0,0,0,1.278.532h5.453a1.743,1.743,0,0,0,1.278-.532,1.743,1.743,0,0,0,.532-1.278h.916a.915.915,0,0,1,.66.245.858.858,0,0,1,.256.65Z" fill="#282828"/>
        </svg>
        <span>アンケート</span>
      </router-link>
    </li>
    <li>
      <router-link to="/invite-friend"
                   :class="{'active': $route.name === 'InviteFriend'}"
                   class="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.999" height="16.66" viewBox="0 0 19.999 16.66">
          <path id="users" d="M10.839,10H4.16a4.229,4.229,0,0,0-1.621.312,4.36,4.36,0,0,0-1.328.889,3.9,3.9,0,0,0-.879,1.318A4.06,4.06,0,0,0,0,14.16v1.66a.831.831,0,0,0,.225.615.831.831,0,0,0,.615.225.805.805,0,0,0,.6-.225.831.831,0,0,0,.225-.615V14.16a2.468,2.468,0,0,1,2.5-2.5h6.679a2.468,2.468,0,0,1,2.5,2.5v1.66a.831.831,0,0,0,.225.615.805.805,0,0,0,.6.225.776.776,0,0,0,.84-.84V14.16a4.06,4.06,0,0,0-.332-1.641,3.9,3.9,0,0,0-.879-1.318,4.36,4.36,0,0,0-1.328-.889A4.229,4.229,0,0,0,10.839,10ZM7.5,8.32A4.28,4.28,0,0,0,9.14,8.008,4.16,4.16,0,0,0,11.347,5.8,4.28,4.28,0,0,0,11.66,4.16a4.28,4.28,0,0,0-.312-1.641A4.16,4.16,0,0,0,9.14.312a4.463,4.463,0,0,0-3.281,0A4.16,4.16,0,0,0,3.652,2.519,4.28,4.28,0,0,0,3.34,4.16,4.28,4.28,0,0,0,3.652,5.8,4.16,4.16,0,0,0,5.859,8.008,4.28,4.28,0,0,0,7.5,8.32Zm0-6.66A2.468,2.468,0,0,1,10,4.16a2.468,2.468,0,0,1-2.5,2.5A2.468,2.468,0,0,1,5,4.16a2.468,2.468,0,0,1,2.5-2.5Zm9.336,8.5a.935.935,0,0,0-.625.107.667.667,0,0,0-.371.478.828.828,0,0,0,.1.625.7.7,0,0,0,.488.371,2.271,2.271,0,0,1,1.309.869,2.51,2.51,0,0,1,.508,1.553v1.66a.812.812,0,0,0,.234.615.928.928,0,0,0,1.211,0,.812.812,0,0,0,.234-.615V14.16a3.888,3.888,0,0,0-3.086-4ZM13.5.156a.663.663,0,0,0-.6.049.908.908,0,0,0-.4.537.935.935,0,0,0,.107.625.667.667,0,0,0,.478.371,2.468,2.468,0,0,1,1.543,1.143,2.467,2.467,0,0,1,.293,1.943A2.613,2.613,0,0,1,13.085,6.66a.754.754,0,0,0-.508.371.678.678,0,0,0-.078.625.761.761,0,0,0,.293.488.886.886,0,0,0,.547.176H13.5a4.459,4.459,0,0,0,1.943-1.094A3.725,3.725,0,0,0,16.5,5.332a4.54,4.54,0,0,0,.1-1.719,4.583,4.583,0,0,0-.537-1.562A3.8,3.8,0,0,0,15,.859,3.9,3.9,0,0,0,13.5.156ZM20,16.66" transform="translate(0)" fill="#282828"/>
        </svg>
        <span>お友達紹介</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ListMenu',

  computed: {
    ...mapState('user', ['flagModalSearch'])
  },

  data () {
    return {
      colorPink: false
    }
  },

  created () {
    if (this.$route.query.typeColor) {
      this.colorPink = (this.$route.query.typeColor === '2')
    }
  },

  watch: {
    '$route.query.typeColor': {
      handler () {
        this.colorPink = (this.$route.query.typeColor === '2')
      }
    }
  },

  methods: {
    toggleSearch () {
      this.$emit('toggleSearch')
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  font-family: $font-family-title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 15px 11px;

  @media #{$info-tablet-horizontal} {
    padding: 23px 15px 11px;
  }
  @media #{$info-phone} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 30;
    padding:4px 18px 2px;
    background: #ffffff;
    border-top: 1px solid #444444;
    align-items: stretch;
  }
   li {
     cursor: pointer;
     @media #{$info-tablet-horizontal} {
       margin-left: 10px;
     }
     @media #{$info-phone} {
       margin-left: 0;
     }
     .item {
       display: flex;
       align-items: center;
       color: $default-color;
       padding: 5px 10px 5px 0;
       border-bottom: 4px solid transparent;
       transition: .5s all;
        @media #{$info-phone} {
          flex-direction: column;
          font-size: 12px;
          border-bottom: 0;
          align-items: center;
          padding: 0 10px 0 0;
          height: 100%;
        }
       &.active {
         font-family: $font-family-title-B;
         color: $default-green;
         border-bottom: 4px solid rgba($default-green, 0.5);
         transition: .5s all;
         @media #{$info-phone} {
           border-bottom: 0;
         }
         path {
           fill: $default-green;
         }
         &.colorPink {
           color: #A54B67;
           border-bottom: 4px solid rgba(#A54B67, 0.5);
           path {
             fill: #A54B67;
           }
         }
       }
       svg {
         margin-right: 8px;
         transition: .5s all;
         @media #{$info-phone} {
           margin: auto auto 3px;
           width: 16px;
         }
         path {
           transition: .5s all;
         }
       }
     }
     &:last-child {
       .item {
         padding-right: 0;
       }
     }
   }
}
</style>
